import React, { useState } from "react";
import { quizData } from "../../config/data";
import { motion, AnimatePresence } from "framer-motion";
import QuestionCard from "../question-card/QuestionCard";

import { ReactComponent as Q1 } from "../../assets/question-icons/q1.svg";
import { ReactComponent as Q2 } from "../../assets/question-icons/q2.svg";
import { ReactComponent as Q3 } from "../../assets/question-icons/q3.svg";
import { ReactComponent as Q4 } from "../../assets/question-icons/q4.svg";
import { ReactComponent as Q5 } from "../../assets/question-icons/q5.svg";

const questionIcons = [<Q1 />, <Q2 />, <Q3 />, <Q4 />, <Q5 />];

function QuestionsPhase({ currentIdx, setCurrentIdx, goNextPhase }) {
  //const [currentIdx, setCurrentIdx] = useState(0);

  const slideVariants = {
    upcoming: {
      transform: "translateX(100%)",
      // duration: 0.6
    },
    previous: {
      //transform: "translateX(-100%)",
      opacity: 0,
    },
    visible: {
      transform: "translateX(0%)",
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.7,
      },
    },
  };

  const handleGoNext = () => {
    if (currentIdx >= questionIcons.length - 1) {
      goNextPhase();
    } else {
      setCurrentIdx(currentIdx + 1);
    }
  };

  return quizData.map((quizItem, index) => {
    return (
      <AnimatePresence exitBeforeEnter={true}>
        {currentIdx == index && (
          <motion.div
            key={index}
            className="full-screen-card"
            variants={slideVariants}
            initial="upcoming"
            animate="visible"
            exit="previous"
          >
            <QuestionCard
              cardData={quizItem}
              questionIndex={index}
              questionsTotal={quizData.length}
              questionIcon={questionIcons[index]}
              handleGoNext={handleGoNext}
            />
          </motion.div>
        )}
      </AnimatePresence>
    );
  });
}

export default QuestionsPhase;

import resultNFT from "../assets/gifts/nft.png";
import resultScotland from "../assets/gifts/scotland.png";
import resultThinkCat from "../assets/gifts/thinkingcat.png";
import resultAngryCat from "../assets/gifts/catsgetangry.png";
import resultSeduction from "../assets/gifts/seduction.png";
import resultBelly from "../assets/gifts/bellybutton.png";
import resultsCage from "../assets/gifts/cagepackage.png";
import resultChicken from "../assets/gifts/chickenarms.png";
import resultPajama from "../assets/gifts/suitjamas.png";

export const quizData = [
  {
    question: "What is your relationship to the person?",
    answers: [
      "Family",
      "Friend",
      "Client",
      "Boss",
      "Facebook Friends",
      "Frenemy",
      "Stalker",
    ],
  },
  {
    question: "What do they do for a living?",
    answers: ["Innovate", "Decorate", "Pontificate", "Dominate"],
  },
  {
    question: "Which element most closely resembles their spirit?",
    answers: ["Water", "Fire", "Air", "Earth", "Barium", "Uranium"],
  },
  {
    question: "How long have you known them?",
    answers: ["0-5 years", "5-75 years", "More than 75 years"],
  },
  {
    question: "If their life were made into a movie, what genre would it be?",
    answers: ["Comedy", "Action", "Arthouse", "Drama", "Sci-Fi", "Horror"],
  },
];

export const resultsData = [
  {
    title: "Their very own NFT",
    url: "https://rarible.com/token/0xF6793dA657495ffeFF9Ee6350824910Abc21356C:58700058526407236693487893813253878576225752149501086938914341359625334423554?tab=details",
    img: resultNFT,
    width: 604,
  },
  {
    title: "1 square meter of Scotland",
    url: "https://www.highlandtitles.com/",
    img: resultScotland,
    width: 1196,
  },
  {
    title: "Thinking Cat sculpture",
    url: "https://www.etsy.com/listing/866160281/purple-clay-thinker-cat-statue-feng-shui?show_sold_out_detail=1&source=aw&utm_source=affiliate_window&utm_medium=affiliate&utm_campaign=us_location_buyer&utm_content=141392&awc=6220_1638199090_5359b1fe868f38abc64eddf09fe0b9bf&utm_term=0",
    img: resultThinkCat,
    width: 950,
  },
  {
    title: "When Cats Get Angry book",
    url: "https://www.amazon.com/When-Cats-Angry-Animal-Emotions/dp/0766088650",
    img: resultAngryCat,
    width: 740,
  },
  {
    title: "The Timeless Art Of Seduction inspired by Seinfeld",
    url: "https://www.etsy.com/listing/887124351/costanza-painting-poster-print-the?show_sold_out_detail=1&source=aw&utm_source=affiliate_window&utm_medium=affiliate&utm_campaign=us_location_buyer&utm_content=141392&awc=6220_1638199224_8a5d508288a11814464e18562806f4f9&utm_term=0",
    img: resultSeduction,
    width: 844,
  },
  {
    title: "Belly button lint brush",
    url: "https://www.etsy.com/listing/673441241/belly-button-lint-brush-funny-gifts-gag?show_sold_out_detail=1&source=aw&utm_source=affiliate_window&utm_medium=affiliate&utm_campaign=us_location_buyer&utm_content=141392&awc=6220_1638199261_1c4408ede3ab7c2006c52553092cb4e4&utm_term=0",
    img: resultBelly,
    width: 808,
  },
  {
    title: "Nicolas Cage wrapping paper",
    url: "https://www.etsy.com/listing/702439769/nicolas-cage-creepy-head-gift-wrap?show_sold_out_detail=1&source=aw&utm_source=affiliate_window&utm_medium=affiliate&utm_campaign=us_location_buyer&utm_content=141392&awc=6220_1638199375_b9d17f7703235ba4f3c092b43c1f7784&utm_term=0",
    img: resultsCage,
    width: 800,
  },
  {
    title: "Baby chicken arms",
    url: "https://www.amazon.com/dp/B09D8PBXW9/?tag=097-20&ascsubtag=v7_1_3_3kai_5ivh_0_x01_-srt-",
    img: resultChicken,
    width: 576,
  },
  {
    title: "Suit pajamas",
    url: "https://www.amazon.com/dp/B0085NX5GU/?tag=097-20&ascsubtag=v7_1_3_r_6a8_0_x01_-srt-",
    img: resultPajama,
    width: 460,
  },
];

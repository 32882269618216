import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import "./App.scss";

import IntroPhase from "./components/intro-phase/IntroPhase";
import QuestionsPhase from "./components/questions-phase/QuestionsPhase";
import ResultScreen from "./components/result-screen/ResultScreen";

import { resultsData } from "./config/data";
import GoGifterator from "./components/go-gifterator/GoGifterator";

function App() {
  const [phaseName, setPhaseName] = useState("intro"); //intro, questions, go, results
  const [questionIndex, setQuestionIndex] = useState(0);
  const [resultIdx, setResultIdx] = useState(null);

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  useEffect(() => {
    // setResultIdx(getRandomInt(resultsData.length));
    debugRandomizeGift();
  }, []);

  const debugRandomizeGift = () => {
    const currentResult = resultIdx;
    var newResult = getRandomInt(resultsData.length);
    while (newResult === currentResult) {
      newResult = getRandomInt(resultsData.length);
    }
    setResultIdx(newResult);
  };

  const slideVariants = {
    upcoming: {
      transform: "translateX(100%)",
    },
    intro: {
      opacity: 0,
    },
    previous: {
      // transform: "translateX(-100%)",
      opacity: 0,
    },
    visible: {
      transform: "translateX(0%)",
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.7,
      },
    },
    immediatelyvisible: {
      transform: "translateX(0%)",
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0,
      },
    },
  };

  const goPrevPhase = () => {
    switch (phaseName) {
      case "questions":
        setPhaseName("intro");
        break;
      case "go":
        setPhaseName("questions");
        break;
      case "results":
        setPhaseName("go");
        break;
    }
  };
  const goNextPhase = () => {
    console.log("Going next phase");
    switch (phaseName) {
      case "intro":
        setPhaseName("questions");
        break;
      case "questions":
        setPhaseName("go");
        break;
      case "go":
        setPhaseName("results");
        break;
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    debugRandomizeGift();
    setQuestionIndex(0);
    setPhaseName("intro");
  };

  return (
    <div className="app-wrap">
      <AnimatePresence exitBeforeEnter={true}>
        {phaseName === "intro" && (
          <AnimatePresence exitBeforeEnter={true}>
            <motion.div
              className="full-screen-card"
              variants={slideVariants}
              initial="intro"
              animate="visible"
              exit="previous"
            >
              <IntroPhase goNextButton={goNextPhase} />
            </motion.div>
          </AnimatePresence>
        )}
        {phaseName === "questions" && (
          <AnimatePresence exitBeforeEnter={true}>
            <motion.div
              className="full-screen-card"
              variants={slideVariants}
              initial="upcoming"
              animate="visible"
              exit="previous"
            >
              <QuestionsPhase
                currentIdx={questionIndex}
                setCurrentIdx={setQuestionIndex}
                goNextPhase={goNextPhase}
              />
            </motion.div>
          </AnimatePresence>
        )}
        {phaseName === "go" && (
          <AnimatePresence exitBeforeEnter={true}>
            <motion.div
              className="full-screen-card"
              variants={slideVariants}
              initial="upcoming"
              animate="immediatelyvisible"
              exit="previous"
            >
              <GoGifterator handleGoNextPhase={goNextPhase} />
            </motion.div>
          </AnimatePresence>
        )}
        {phaseName === "results" && (
          <AnimatePresence exitBeforeEnter={true}>
            <motion.div
              className="full-screen-card"
              variants={slideVariants}
              initial="intro"
              animate="visible"
              exit="previous"
            >
              <ResultScreen resultIdx={resultIdx} handleReset={handleReset} />
            </motion.div>
          </AnimatePresence>
        )}
      </AnimatePresence>
    </div>
  );
}

export default App;

import React from "react";

import { motion } from "framer-motion";

import { ReactComponent as Gifterator } from "../../assets/gifterator-box.svg";

function GoGifterator({ handleGoNextPhase }) {
  return (
    <div>
      <div className="c-panel">
        <div className="section">
          <motion.div
            initial={{
              opacity: 1,
              scale: 0,
              rotate: -720,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              rotate: 0,
              transition: {
                delay: 0.4,
                duration: 3,
              },
            }}
          >
            <div className="c-gifterator">
              <Gifterator />

              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    delay: 3.2,
                  },
                }}
              >
                <button
                  className="button button--big"
                  onClick={() => {
                    handleGoNextPhase();
                  }}
                >
                  Go Gifterator!
                </button>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default GoGifterator;

import React from "react";

import { ReactComponent as IntroIcon } from "../../assets/question-icons/intro.svg";

function IntroPhase({ goNextButton }) {
  return (
    <div className="c-panel">
      <div className="content-holder">
        <div className="section">
          <div class="c-panel__icon">
            <IntroIcon />
          </div>
        </div>
        <div className="section">
          <div class="eyebrow">Welcome to</div>
        </div>
        <div className="section">
          <h1>The Cloudberry Gifterator</h1>
        </div>
        <div className="section">
          <p>
            Have someone on your list who’s tough to shop for?
            <br />
            Just answer a few questions and The Gifterator will suggest the
            perfect gift for your impossible person.
          </p>
        </div>
        <div className="section">
          <button
            className="button"
            onClick={() => {
              goNextButton();
            }}
          >
            Let's get started
          </button>
        </div>
      </div>
    </div>
  );
}

export default IntroPhase;

import React, { useState } from "react";
import { Dropdown } from "reactjs-dropdown-component";
import { motion } from "framer-motion";

function QuestionCard({
  cardData,
  questionIndex,
  questionsTotal,
  questionIcon,
  handleGoPrev,
  handleGoNext,
}) {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const handleOnChange = (item, name) => {
    console.log("Handle change:", item, name);
    setSelectedAnswer(item);
  };

  return (
    <div className="c-panel">
      <div className="content-holder">
        <motion.div
          // initial={{
          //   opacity: 0,
          // }}
          // animate={{
          //   opacity: 1,
          //   transition: {
          //     delay: 1.2,
          //   },
          // }}
          className="section"
        >
          {questionIcon}
        </motion.div>
        <div className="section">
          <div className="eyebrow">
            Question {questionIndex + 1} of {questionsTotal}
          </div>
        </div>
        <div className="section">
          <h2>{cardData.question}</h2>
        </div>
        <div className="section">
          <Dropdown
            name="hey"
            title="[Select one]"
            onChange={handleOnChange}
            list={cardData.answers.map((answer) => {
              return {
                label: answer,
                value: answer,
              };
            })}
          ></Dropdown>
        </div>
        {selectedAnswer && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            className="section"
          >
            <button className="button" onClick={handleGoNext}>
              Next
            </button>
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default QuestionCard;

import React from "react";

import { motion, AnimatePresence } from "framer-motion";

import { ReactComponent as WhiteLogo } from "../../assets/logo-white.svg";
import { ReactComponent as LabelChevron } from "../../assets/chevron-dark.svg";
import { resultsData } from "../../config/data";

import { useWindowSize } from "../../hooks/useWindowSize";

function ResultScreen({ resultIdx, handleReset, debugRandomizeGift }) {
  const size = useWindowSize();

  return (
    <div className="c-panel">
      <AnimatePresence>
        <motion.div
          initial={{
            opacity: 0,
            transform: "translateY(50px)",
            position: "absolute",
            top: 500,
            width: "100%",
            height: "50vh",
          }}
          animate={{
            opacity: 1,
            transform: "translateY(0px)",
            position: "absolute",
            top: 500,
            width: "100%",
            height: "50vh",
            transition: {
              duration: 3,
              delay: 0,
            },
          }}
        >
          <div className="c-result__risingsun"></div>
        </motion.div>
      </AnimatePresence>
      <div className="content-holder">
        <div className="section">
          <h1>Gift selection completed.</h1>
        </div>
        <div className="section">
          <p>
            Based on the information you provided, The Gifterator came up with
            this perfect gift for your person:
          </p>
        </div>

        <div className="section">
          <motion.a
            target="_blank"
            href={resultsData[resultIdx].url}
            className="c-result__wrap"
            initial={{
              opacity: 0,
              scale: 0.2,
              rotate: 0,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              rotate: 0,
              transition: {
                delay: 0,
                duration: 2.5,
              },
            }}
          >
            <div className="c-result__img">
              <div className="imgshadow">
                <img
                  src={resultsData[resultIdx].img}
                  width={resultsData[resultIdx].width / 2}
                />
              </div>
              <img
                src={resultsData[resultIdx].img}
                width={resultsData[resultIdx].width / 2}
              />
            </div>
            <div
              className="c-result__ribbon"
              style={{
                width: size.width <= 550 ? size.width - 160 : 290,
              }}
            >
              <div className="left">&nbsp;</div>
              <div className="mid">
                <div className="label">
                  <span>
                    {resultsData[resultIdx].title}
                    <LabelChevron />
                  </span>
                </div>
              </div>
              <div className="right">&nbsp;</div>
            </div>
          </motion.a>
        </div>

        <div className="section">
          <p class="thanks">THANK YOU FOR THIS YEAR!</p>
        </div>

        <div className="section">
          <WhiteLogo />
        </div>
        <div className="section">
          <h2 className="bigger">Happy Holidays from Cloudberry</h2>
        </div>
        <div className="section">
          <a href="#" onClick={handleReset} className="c-result__again">
            <div>Find a gift for the next impossible person in your life</div>
            <div class="button">Let's Gifterate again!</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ResultScreen;
